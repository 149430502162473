import "./CallTable.scss";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import 'moment/min/locales';
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { CallDetail } from "../../types/call";
import { User } from "../../types/user";
import { CallFilter } from "../../types/filters";
import { Dictionary } from "../../types/Dictionary";
import { ColorCategory } from "../../types/master-data";
import CallListing from "../../components/custom/CallListing";
import CallFilterDrawer from "../../components/CallFilterDrawer";
import LoadingOverlay from "../../components/LoadingOverlay";
import useLocale from "../../hooks/useLocale";
import callsService from "../../services/calls.service";
import usersService from "../../services/users.service";
import { searchCallData } from "../../utils/callDetailUtil";
import { PeriodFilterTypes } from "../../data/PeriodFilterTypes";
import { formatDateTime } from "../../utils/dateTimeUtils";
import { AxiosError } from "axios";
import CustomSearchFieldTextInput from "../../components/custom/form/CustomSearchFieldTextInput";
import useSwal from "../../hooks/useSwal";
import { Page } from "../../types/Page";
import { ApiResponse } from "../../types/ApiResponse";
import { CallTableSortColumnEnum } from "../../enums/CallTableSortColumnEnum";
import { Dates, getDateBasedOnFilterType } from "../../utils/FilterDrawerUtil";
import useTranslation from "../../hooks/useTranslation";

const initialPageDetail: Page = {
  skip: 0,
  take: 10,
  totalRecords: 0,
  sort: {
    sortField: CallTableSortColumnEnum.DateTime,
    sortDirection: "desc",
  },
};

const CallTable: React.FC = () => {
  const trans=useTranslation("calls");
  const localeCtx = useLocale();
  const swal = useSwal();
  const [callData, setCallData] = useState<ApiResponse<CallDetail> | undefined>(
    undefined
  );
  const [currentUserData, setCurrentUserData] = useState<User>();
  const [callFilter, setCallFilter] = useState<CallFilter>();
  const [search, setSearch] = useState<string>("");
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getFilterMasterData();
    const filterValuesCache = sessionStorage.getItem("VoiceAiCallFilter");
    if (filterValuesCache !== null) {
      const filterValues = JSON.parse(filterValuesCache);
      setCallFilter(filterValues);
      sendFetchCallsRequest(filterValues);
    } else {
      const startDateTime = new Date();
      const endDateTime = new Date();
      startDateTime?.setHours(0, 0, 0);
      endDateTime?.setHours(23, 59, 59);
      const filterValues: CallFilter = {
        startDate: moment(startDateTime).format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(endDateTime).format("YYYY-MM-DD HH:mm:ss"),
        periodType: 1,
        filterType: 1,
        page: { ...initialPageDetail },
      };
      setCallFilter(filterValues);
      sendFetchCallsRequest(filterValues);
    }
  }, []);

  useEffect(() => {
    if(callFilter){
      sessionStorage.setItem("VoiceAiCallFilter", JSON.stringify(callFilter));
    }
  }, [callFilter]);

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["calls"]) {
      trans.fetchTranslations("calls");
    }
  }, [localeCtx?.selectedLocale]);

  const sendFetchCallsRequest = async (filter: CallFilter) => {
    try {
      setLoading(true);
      setError(false);
      setCallData({ data: undefined, page: { ...filter.page } });
      if (filter.periodType !== 4) {
        const getFilterBasesDates: Dates = getDateBasedOnFilterType(
          filter.periodType ?? 0,
          filter.filterType ?? 0
        );
        filter = {
          ...filter,
          startDate: moment(getFilterBasesDates.tempStartDate).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          endDate: moment(getFilterBasesDates.tempEndDate).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        };
      }
      const fetchedCalls = await callsService.fetchCalls({
        ...filter,
        searchTerm: search,
        periodType: undefined,
      });
      if (fetchedCalls && fetchedCalls.data && fetchedCalls.data.length > 0) {
        setCallData(fetchedCalls);
      } else {
        setCallData({ data: [], page: { ...initialPageDetail } });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(true);
      }
      setCallData(undefined);
    } finally {
      setLoading(false);
    }
  };

  const getFilterMasterData = async () => {
    try {
      const currentUser = await usersService.getCurrentUser();
      setCurrentUserData(currentUser);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleFilterDialog = () => {
    setFilterVisible(!filterVisible);
  };

  const handleCallDataFilter = (callFilterData: CallFilter) => {
    let _callFilterData: CallFilter = {
      ...callFilterData,
      page: { ...initialPageDetail },
    };
    setCallFilter(_callFilterData);
    setFilterVisible(!filterVisible);
    sendFetchCallsRequest({ ..._callFilterData });
  };

  const handleCallFlagUpdate = async (
    selectedFlag: ColorCategory | null,
    callDetailData: CallDetail
  ) => {
    if (selectedFlag === null) {
      selectedFlag = { id: 0, name: "", hexCode: "" };
    }
    if (callData?.data) {
      var tempCalls = callData.data.map((cd) => {
        if (cd.platformCallDetailId === callDetailData.platformCallDetailId) {
          cd.colorCategory = selectedFlag !== null ? selectedFlag : undefined;
        }
        return cd;
      });
      setCallData({ ...callData, data: tempCalls });
    }
  };

  const handlePaginationChange = (pageDetail: Page) => {
    setCallFilter({ ...callFilter, page: pageDetail });
    sendFetchCallsRequest({ ...callFilter, page: pageDetail });
  };

  const handleSortChange = (sortField: string, sortDirection: string) => {
    let _pageFilter: Page = { ...initialPageDetail };
    _pageFilter.sort = {
      sortDirection: sortDirection,
      sortField: sortField,
    };
    setCallFilter({ ...callFilter, page: _pageFilter });
    sendFetchCallsRequest({ ...callFilter, page: _pageFilter });
  };

  const handleSearchTextOnKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      let _filter: CallFilter = {
        ...callFilter,
        page: { ...initialPageDetail },
      };
      setCallFilter(_filter);
      sendFetchCallsRequest(_filter);
    }
  };

  return (
    <div className="callList float-left w-100 maxTableCol m-b-20 mt-n2">
      {trans.translationsLoading && (
        <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px" }}
          themeColor={"light"}
          size={"medium"}
          loadingText={trans.fetchLabelKeyTranslation(
            "SwitchLanguageText",
            ""
          )}
        />
      )}
      <div className="callListInner">
        <div className="callListBox">
          <div className="row">
            <div className="col-md-12">
              <div className="tableTitle d-flex align-items-center justify-content-between">
                <div>
                  <span className="fs-13 font-weight-semi d-flex align-items-center">
                    {trans.translationsLoading
                      ? "Calls"
                      : trans.fetchLabelKeyTranslation(
                          "CallsGridTitle",
                          "Calls"
                        )}{" "}
                    (
                    {callData?.data
                      ? callData.data.filter((el) => searchCallData(el, search))
                          .length
                      : 0}
                    )
                  </span>
                  <div>
                    <span
                      style={{ fontWeight: "500" }}
                      className="fs-10 text-black-8"
                    >
                      {callFilter?.periodType === 4
                        ? callFilter.startDate && callFilter.endDate
                          ? formatDateTime(
                              callFilter.startDate,
                              "MMM DD, YYYY"
                            ) +
                            " - " +
                            formatDateTime(callFilter.endDate, "MMM DD, YYYY")
                          : "Custom"
                        : PeriodFilterTypes.find(
                            (e) => e.id === callFilter?.periodType
                          )?.filters?.find(
                            (el) => el.id === callFilter?.filterType
                          )?.title}
                    </span>
                  </div>
                </div>
                <div className="buttons-container hov-transparent d-flex align-itmes-center">
                  <div className="searchBox searchCol">
                    <CustomSearchFieldTextInput
                      className="input-search"
                      placeholder={trans.translationsLoading
                        ? "Search..."
                        : trans.fetchLabelKeyTranslation(
                            "SearchPlaceholder",
                            "Search..."
                          )}
                      value={search}
                      updateValue={setSearch}
                      searchTextChangeHandler={handleSearchChange}
                      handleOnKeyDown={handleSearchTextOnKeyDown}
                      onEscapeKeyFunc={true}
                    />
                  </div>
                  {!filterVisible && (
                    <Tooltip
                      anchorElement={"target"}
                      parentTitle={true}
                      position={"top"}
                      style={{ zIndex: 999 }}
                    >
                      <Button
                        className="btn btn-link iconBtn p-0 m-l-10"
                        onClick={handleFilterDialog}
                      >
                        <i
                          className="bi bi-sliders fs-20 line-height-1 text-primary"
                          title="Filter"
                        ></i>
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
          <CallListing
            isBasic={filterVisible}
            loading={loading}
            error={error}
            callData={callData?.data}
            handleCallFlagUpdate={handleCallFlagUpdate}
            enableServerSidePagination={true}
            searchTerm={""}
            pageDetail={callData?.page}
            handlePaginationChange={handlePaginationChange}
            handleSortingChange={handleSortChange}
          />
        </div>
        {filterVisible && (
          <div className="callListBoxFilter">
            <div className="listBoxCard">
              <div className="float-left w-100">
                <div
                  className="float-left w-100 d-flex fs-14 align-items-center justify-content-between font-weight-semi p-t-3 p-r-10 p-b-3 p-l-15"
                  style={{ minHeight: "36px" }}
                >
                  <div className="float-left">
                    <span className="p-r-5">{trans.translationsLoading
                        ? "Date:"
                        : trans.fetchLabelKeyTranslation(
                            "DateText",
                            "Date:"
                          )}</span>
                    <span className="panelTitleBadge fs-12">
                      {callFilter?.periodType === 4
                        ? callFilter.startDate && callFilter.endDate
                          ? formatDateTime(
                              callFilter.startDate,
                              "MMM DD, YYYY"
                            ) +
                            " - " +
                            formatDateTime(callFilter.endDate, "MMM DD, YYYY")
                          : "Custom"
                        : PeriodFilterTypes.find(
                            (e) => e.id === callFilter?.periodType
                          )?.filters?.find(
                            (el) => el.id === callFilter?.filterType
                          )?.title}
                    </span>
                  </div>
                  <span className="float-right">
                    <Tooltip
                      anchorElement={"target"}
                      parentTitle={true}
                      position={"top"}
                      style={{ zIndex: 999 }}
                    >
                      <Button
                        className="buttons-container-button radius-50 p-0"
                        style={{ width: "30px", height: "30px" }}
                        title="Close"
                        onClick={handleFilterDialog}
                      >
                        <i className="bi bi-x-lg fs-20 line-height-1 text-primary"></i>
                      </Button>
                    </Tooltip>
                  </span>
                </div>
                <CallFilterDrawer
                  userMasterData={currentUserData}
                  callFilterData={callFilter}
                  handleCallDataFilter={handleCallDataFilter}
                  handleFilterDialog={handleFilterDialog}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CallTable;
