import React, { useEffect, useState } from "react";
import CustomSearchFieldTextInput from "../../../../components/custom/form/CustomSearchFieldTextInput";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridNoRecords,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { SortDescriptor, orderBy, process } from "@progress/kendo-data-query";
import { Tooltip } from "@progress/kendo-react-tooltip";
import UpsertAPIAccess from "./UpsertAPIAccess";
import { APIToken } from "./APIAccessProps";
import moment from "moment";
import apiService from "../../../../services/apiAccess.service";
import useAuth from "../../../../hooks/useAuth";
import { Loader } from "@progress/kendo-react-indicators";
import { TinyUserGroup } from "../../../../types/user/UserGroupRelation";
import { formatDateTime } from "../../../../utils/dateTimeUtils";
import { SweetAlertOptions } from "sweetalert2";
import { AxiosError } from "axios";
import useSwal from "../../../../hooks/useSwal";
import { User } from "../../../../types/user";
import usersService from "../../../../services/users.service";
import { Dictionary } from "../../../../types/Dictionary";
import useLocale from "../../../../hooks/useLocale";
import useMasterData from "../../../../hooks/useMasterData";
import APIAccessForMobile from "./APIAccessForMobile";

interface Page {
  skip: number;
  take: number;
}

interface APIAccessProps {
  setPageFreezeLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const APIAccess: React.FC<APIAccessProps> = ({ setPageFreezeLoader }) => {
  const initialDataState: Page = {
    skip: 0,
    take: 10,
  };
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();
  const masterData = useMasterData();
  const [visible, setVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pageData, setPageData] = useState<Page>(initialDataState);
  const [tokens, setTokens] = useState<APIToken[]>([]);
  const [upsertTokenData, setUpsertTokenData] = useState<APIToken | undefined>();
  const [sort, setSort] = useState([
    { field: "tokenExpiry", dir: "asc" },
  ] as SortDescriptor[]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
    "APIAccess"
    ]
  );


  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
      "APIAccess"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    fetchTokens();
  }, [auth?.tokenPayload?.CustomerId]);

  useEffect(() => {
    getCurrentUser();
  }, [])

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "APIAccess"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
        "APIAccess"
        ]
      );
      localeCtx?.setPreviousAppLocale("APIAccess");
      if (localeCtx?.localeSwitchFailed) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const fetchTokens = async () => {
    setLoading(true);
    setError(false);
    try{
      const allTokens:APIToken[]=await apiService.getAllTokens(auth?.tokenPayload?.CustomerId??0);
      const sortedTokens: APIToken[] = allTokens.sort((a: APIToken, b: APIToken) => {
        if (moment(a.tokenExpiry) > moment(b.tokenExpiry))
          return 1;
        if (moment(a.tokenExpiry) < moment(b.tokenExpiry))
          return -1;
        return 0;
      });
      setTokens(sortedTokens);
    } catch (error) {
      setError(true);
      setTokens([])
    } finally {
      setLoading(false);
    }
  };

  const getCurrentUser = async () => {
    try {
      const user = await usersService.getCurrentUser();
      setCurrentUser(user);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    }
  };

  const toggleDialog = () => {
    setVisible(!visible);
    setUpsertTokenData(undefined);
  };

  const searchToken = (tokenData: APIToken) => {
    const searchVal = searchTerm.trim().toLocaleLowerCase();
    if (tokenData.tokenName.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    return false;
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
    setPageData(initialDataState);
  };

  const handlePageChange = (event: any) => {
    setPageData(event.page);
  };

  const apiAccessUpsertHandler = (updatedToken?: APIToken, insertedToken?: APIToken) => {
    if (insertedToken) {
      const updatedTokensList: APIToken[] = [...tokens, insertedToken]
      setSearchTerm("");
      setTokens(updatedTokensList);
      const currentPageNumber = Math.floor(
        updatedTokensList.length / pageData.take
      );
      setPageData({
        skip:
          updatedTokensList.length % pageData.take === 0
            ? (currentPageNumber - 1) * pageData.take
            : currentPageNumber * pageData.take,
        take: pageData.take,
      });
    } else {
      if (updatedToken) {
        const updatedTokensList: APIToken[] =
          tokens.map((token: APIToken) => {
            if (token.id === updatedToken.id) {
              token.tokenExpiry =
                updatedToken.tokenExpiry;
            }
            return token;
          });
        setTokens(updatedTokensList);
      }
    }
  }

  const StatusCustomCell = (props: GridCellProps) => {
    return (
      <td className="uCardCol mx-th-tag">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className="cursor-default text-decoration-none float-left w-100 line-height-1 mx-td-spn">
                <i style={{ fontSize: "8px", color: new Date() > new Date(props.dataItem.tokenExpiry) ? "red" : "blue" }}
                  className="bi bi-circle-fill"></i>&nbsp;{new Date() > new Date(props.dataItem.tokenExpiry) ?
                    translationsLoading
                      ? " Expired"
                      : fetchLabelKeyTranslation("ExpiredStatusText", " Expired")
                    : translationsLoading
                      ? "Active"
                      : fetchLabelKeyTranslation("ActiveStatusText", " Active")}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const AuthorizationProfileCustomCell = (props: GridCellProps) => {
    return (
      <td className="uCardCol mx-th-tag">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className="cursor-default text-decoration-none float-left w-100 line-height-1 mx-td-spn">
                <span className="badgeList">
                  <div className="keywordRow">
                    {props.dataItem.authorizationProfile.name}
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const UserGroupsCustomCell = (props: GridCellProps) => {
    return (
      <td className="uCardCol mx-th-tag">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className="cursor-default text-decoration-none float-left w-100 line-height-1 mx-td-spn">
                {props.dataItem.recordingUserGroups.map((group: TinyUserGroup) => {
                  return (
                    <div className="keywordTrk">{group.name}</div>
                  )
                })}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const TokenExpiryCustomCell = (props: GridCellProps) => {
    return (
      <td className="uCardCol mx-th-tag">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className="cursor-default text-decoration-none float-left w-100 line-height-1 mx-td-spn">
                {formatDateTime(props.dataItem.tokenExpiry, "MMM DD, YYYY, HH:mm")}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };
  const onEditHandler = (data: APIToken) => {
    setUpsertTokenData(data);
    setVisible(true);
  };

  const onDeleteHandler = async (data: APIToken) => {
    setPageFreezeLoader(false);
    const swalOptions: SweetAlertOptions<any, any> = {
      title: translationsLoading
        ? "Are you sure?"
        : fetchLabelKeyTranslation("SwtWarningDltTitle", "Are you sure?"),
      text: translationsLoading
        ? "You won't be able to revert this!"
        : fetchLabelKeyTranslation("SwtWarningDltText", "You won't be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translationsLoading
        ? "Yes, delete it!"
        : fetchLabelKeyTranslation("SwtWarningDltConfirmText", "Yes, delete it!"),
      cancelButtonText: translationsLoading
        ? "Cancel"
        : fetchLabelKeyTranslation("SwtWarningDltCancelText", "Cancel"),
    };
    const result = await swal.fire(swalOptions);
    if (result.isConfirmed) {
      setPageFreezeLoader(true);
      try {
        await apiService.deleteToken(data.id, auth?.tokenPayload?.CustomerId ?? 0);
        setPageFreezeLoader(false);
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: translationsLoading
            ? "Token is deleted"
            : fetchLabelKeyTranslation("SwtSuccessDltTitle", "Token is deleted"),
          confirmButtonText: translationsLoading
            ? "OK"
            : fetchLabelKeyTranslation("SwtSuccessDltConfirmText", "OK"),
        };
        await swal.fire(swalOptions);
        const updatedTokens = tokens?.filter(
          (token: APIToken) => token.id !== data.id
        );
        setTokens(updatedTokens);
        const gridDataResult = process(
          updatedTokens.filter((token: APIToken) =>
            searchToken(token)
          ),
          pageData
        );
        if (
          gridDataResult.data.length === 0 &&
          gridDataResult.total > 0
        ) {
          const currentPageNumber = Math.floor(
            gridDataResult.total / pageData.take
          );
          setPageData({
            skip:
              gridDataResult.total % pageData.take === 0
                ? (currentPageNumber - 1) * pageData.take
                : currentPageNumber * pageData.take,
            take: pageData.take,
          });
        }
      }
      catch (err) {
        if (err instanceof AxiosError) {
          let errorMessage: string = translationsLoading
            ? "Uh Oh! Something Went Wrong. Please Try Again!"
            : fetchLabelKeyTranslation("SwtFailureDltErrorMssg", "Uh Oh! Something Went Wrong. Please Try Again!");
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "error",
            title: translationsLoading
              ? "Error"
              : fetchLabelKeyTranslation("SwtFailureDltTitle", "Error"),
            text: errorMessage,
            confirmButtonText: translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("SwtFailureDltConfirmText", "OK"),
          };
          swal.fire(swalOptions);
        }
      } finally {
        setPageFreezeLoader(false);
      }
    }
  };

  const CustomCellWithActions = (props: GridCellProps) => {
    return (
      <td className="text-right">
        <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
          <span className="iBtn d-flex justify-content">
            <Button
              className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn 
              ${masterData?.telepoDisableConfig?.systemSetting.apiAccess.disableChanges ? "disabledBtn" : ""
                }`}
              onClick={() => onEditHandler(props.dataItem)}
              disabled={
                masterData?.telepoDisableConfig?.systemSetting.apiAccess
                  .disableChanges
              }
            >
              <span className="fs-17 line-height-1 text-primary" title={translationsLoading
                ? "Edit"
                : fetchLabelKeyTranslation("EditTitleText", "Edit")}>
                <i className="bi bi-pencil"></i>
              </span>
            </Button>
            <Button
              className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn
              ${masterData?.telepoDisableConfig?.systemSetting.apiAccess.disableChanges ? "disabledBtn" : ""
                }`}
              onClick={() => onDeleteHandler(props.dataItem)}
              disabled={
                masterData?.telepoDisableConfig?.systemSetting.apiAccess
                  .disableChanges
              }
            >
              <span
                className="fs-17 line-height-1 text-primary"
                title={translationsLoading
                  ? "Delete"
                  : fetchLabelKeyTranslation("DeleteTitleText", "Delete")}
              >
                <i className="bi bi-trash3"></i>
              </span>
            </Button>
          </span>
        </Tooltip>
      </td>
    );
  };

  const CustomCellForMobileView = (mobileViewProps: GridCellProps) => {
    return (
      <APIAccessForMobile
        {...mobileViewProps}
        loading={loading}
        error={error}
        onEditHandler={onEditHandler}
        onDeleteHandler={onDeleteHandler}
      />
    );
  };

  return (
    <div className="callList float-left w-100 maxTableCol m-b-20">
      <div className="callListInner">
        <div className="callListBox">
          <div className="row">
            <div className="col-md-12">
              <div className="tableTitle d-flex flex-wrap align-items-center justify-content-between trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">{translationsLoading
                  ? "API Access"
                  : fetchLabelKeyTranslation("APIAccessTitle", "API Access")}</span>
                <div className="buttons-container hov-transparent d-flex flex-wrap align-itmes-center">
                  <div className="searchBox searchCol m-r-10 p-b-3">
                    <CustomSearchFieldTextInput
                      className="input-search"
                      placeholder={translationsLoading
                        ? "Search..."
                        : fetchLabelKeyTranslation("SearchPlaceholderText", "Search...")}
                      value={searchTerm}
                      updateValue={setSearchTerm}
                      searchTextChangeHandler={handleSearchChange}
                      onEscapeKeyFunc={true}
                      disabled={loading}
                    />
                  </div>
                  <Button
                    disabled={loading ||
                      masterData?.telepoDisableConfig?.systemSetting.apiAccess.disableChanges}
                    onClick={toggleDialog}
                    className={`btn bg-primary text-white fs-13 fw-normal
                      ${loading || masterData?.telepoDisableConfig?.systemSetting.apiAccess.disableChanges ? "disabledBtn" : ""}`}
                    style={{ height: "29px", margin: "-1px 0 0 0" }}
                    title={`+ ${translationsLoading
                      ? "New Token"
                      : fetchLabelKeyTranslation("NewTokenTitle", "New Token")}`}
                  >
                    {`+ ${translationsLoading
                      ? "New Token"
                      : fetchLabelKeyTranslation("NewTokenTitle", "New Token")}`}
                  </Button>
                  {visible && (
                    <UpsertAPIAccess
                      toggleDialog={toggleDialog}
                      upsertTokenData={upsertTokenData}
                      apiAccessUpsertHandler={apiAccessUpsertHandler}
                      currentUser={currentUser}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="tableList rowSpace float-left w-100">
                <div className="tableMain gridContentOverflowY-hidden table-mobile">
                  <Grid
                    data={orderBy(
                      tokens
                        .filter((token: APIToken) => searchToken(token))
                        .slice(
                          pageData.skip,
                          pageData.take + pageData.skip
                        ) ?? [],
                      sort
                    )}
                    skip={pageData.skip}
                    take={pageData.take}
                    total={
                      tokens?.filter((token: APIToken) => searchToken(token))
                        .length
                    }
                    pageable={{ buttonCount: 4, pageSizes: true }}
                    onPageChange={handlePageChange}
                    sort={sort}
                    sortable={true}
                    onSortChange={(e: GridSortChangeEvent) => {
                      let tempSort = e.sort;
                      if (e.sort.length === 0 && sort.length !== 0) {
                        tempSort = sort.map((s) => {
                          return { field: s.field, dir: "asc" };
                        });
                      }
                      setTokens(
                        orderBy(
                          tokens?.filter((token: APIToken) =>
                            searchToken(token)
                          ) ?? [],
                          tempSort
                        )
                      );
                      setSort(tempSort);
                    }}
                  >
                    <GridNoRecords>
                      {!loading && error ? <span className="fs-15">
                        <i className="bi bi-exclamation-triangle-fill tx-amber"></i>
                        {translationsLoading
                          ? "Uh Oh! Something Went Wrong. Please Try Again!"
                          : fetchLabelKeyTranslation("FetchTokensErrMssg", "Uh Oh! Something Went Wrong. Please Try Again!")}
                      </span> : loading ? <Loader type={"infinite-spinner"} /> : translationsLoading
                        ? "No tokens found"
                        : fetchLabelKeyTranslation("NoTokensFoundErrMssg", "No tokens found")}
                    </GridNoRecords>
                    <GridColumn
                      width={0}
                      field="cellMobile"
                      cell={CustomCellForMobileView}
                    />
                    <GridColumn
                      field="tokenName"
                      title={translationsLoading
                        ? "Token name"
                        : fetchLabelKeyTranslation("TokenNameGridTitle", "Token name")}
                      sortable={false}
                    />
                    <GridColumn
                      title={translationsLoading
                        ? "Status"
                        : fetchLabelKeyTranslation("StatusGridTitle", "Status")}
                      cell={StatusCustomCell}
                      sortable={false}
                    />
                    <GridColumn
                      field="authorizationProfile"
                      title={translationsLoading
                        ? "Permission Group"
                        : fetchLabelKeyTranslation("PermissionGroupGridTitle", "Permission Group")}
                      cell={AuthorizationProfileCustomCell}
                      sortable={false}
                    />
                    <GridColumn
                      field="recordingNetworkAccessibility.name"
                      title={translationsLoading
                        ? "Recording Network"
                        : fetchLabelKeyTranslation("RecordingNetworkGridTitle", "Recording Network")}
                      sortable={false}
                    />
                    <GridColumn
                      field="recordingUserGroups"
                      title={translationsLoading
                        ? "User Groups"
                        : fetchLabelKeyTranslation("UserGroupsGridTitle", "User Groups")}
                      cell={UserGroupsCustomCell}
                      sortable={false}
                    />
                    <GridColumn
                      field="tokenExpiry"
                      title={translationsLoading
                        ? "Token Expiry"
                        : fetchLabelKeyTranslation("TokenExpiryGridTitle", "Token Expiry")}
                      cell={TokenExpiryCustomCell}
                      sortable={true}
                    />
                    <GridColumn
                      field="apiAccessActions"
                      title={translationsLoading
                        ? "Actions"
                        : fetchLabelKeyTranslation("ActionsGridTitle", "Actions")}
                      cell={CustomCellWithActions}
                      sortable={false}
                    />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default APIAccess;
