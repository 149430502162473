import { RecordingTrackSegment, TrackSegment } from "../types/player-track";
import {
  RecordingMoment,
  RecordingSegmentSentiment,
  RecordingTopic,
} from "../types/recording";
import { RawRecordingTranscription } from "../types/transcription/RawRecordingTranscription";

const momentsTrackCssClasses = ["Perfection", "greetings", "knowledge"];
const sentimentsTrackCssClasses = new Map();
sentimentsTrackCssClasses.set("Positive", "happy bg-success");
sentimentsTrackCssClasses.set("Negative", "unhappy bg-danger");
sentimentsTrackCssClasses.set("Neutral", "neutral bg-warning");

export const processTrackSegments = (
  segments: RecordingTrackSegment[],
  totalDuration: number
): TrackSegment[] => {
  const res: TrackSegment[] = [];
  if (segments.length === 0) {
    return res;
  }

  let cnt = 1;
  segments.forEach((s) => {
    res.push({
      id: cnt,
      label: s.label,
      width: ((s.end - s.start) / totalDuration) * 100,
      left: ((s.start - 0) / totalDuration) * 100,
      cssLabel: s.cssLabel,
    });
    cnt += 1;
  });

  return res;
};

export const extractTranscriptInsights = (
  rawTranscript: RawRecordingTranscription,
  totalDuration: number
): RecordingTrackSegment[] => {
  const res: RecordingTrackSegment[] = [];
  const transcriptWords = rawTranscript.words;

  let lastEndTimestamp = 0;
  let i = 0;
  while (i < transcriptWords.length) {
    // cross talk
    if (transcriptWords[i].start < lastEndTimestamp) {
      res.push({
        label: "Cross Talk",
        cssLabel: "cross-talk",
        start: transcriptWords[i].start,
        end: Math.min(transcriptWords[i].end, lastEndTimestamp),
      });
    }

    // silence both sides
    if (transcriptWords[i].start > lastEndTimestamp && transcriptWords[i].word !== "[locked]") {
      res.push({
        label: "Silence Both Ends",
        cssLabel: "silence-both",
        start: lastEndTimestamp,
        end: transcriptWords[i].start,
      });
    }
    if(transcriptWords[i].word === "[locked]"){
      res.push({
        label: "Silence Both Ends",
        cssLabel: "silence-both",
        start: lastEndTimestamp,
        end: transcriptWords[i].end,
      });
    }

    // redacted part
    if (
      transcriptWords[i].word.includes("*") ||
      transcriptWords[i].word.startsWith("[CREDIT_CARD") ||
      transcriptWords[i].word.startsWith("[CREDIT_CARD_EXPIRATION") ||
      transcriptWords[i].word.startsWith("[CVV")
    ) {
      res.push({
        label: "Redacted Information",
        cssLabel: "redacted",
        start: transcriptWords[i].start,
        end: transcriptWords[i].end,
      });
    }

    if (
      !(
        transcriptWords[i].word.includes("*") ||
        transcriptWords[i].word === "[locked]" ||
        transcriptWords[i].word.startsWith("[CREDIT_CARD") ||
        transcriptWords[i].word.startsWith("[CREDIT_CARD_EXPIRATION") ||
        transcriptWords[i].word.startsWith("[CVV")
      )
    ) {
      res.push({
        label: `participant ${
          parseInt(transcriptWords[i].speaker.toString()) + 1
        }`,
        cssLabel: `speaker-${
          parseInt(transcriptWords[i].speaker.toString()) % 5
        }`,
        start: transcriptWords[i].start,
        end: transcriptWords[i].end,
      });
    }
    lastEndTimestamp = transcriptWords[i].end;
    i++;
  }

  if (rawTranscript.words.length === 0 || rawTranscript.words[0].start > 0) {
    res.unshift({
      label: "Silence Both Ends",
      cssLabel: "silence-both",
      start: 0,
      end:
        rawTranscript.words.length === 0
          ? totalDuration
          : rawTranscript.words[0].start,
    });
  }

  if (res[res.length - 1].end < totalDuration) {
    res.push({
      label: "Silence Both Ends",
      cssLabel: "silence-both",
      start: res[res.length - 1].end,
      end: totalDuration,
    });
  }

  const participants: RecordingTrackSegment[] = [];
  let idx = 0;
  while (idx < res.length) {
    if (res[idx].label.startsWith("participant")) {
      const participant = res[idx].label;
      const cssParticipantLabel = res[idx].cssLabel;
      const start = res[idx].start;
      let end = res[idx].end;
      while (idx < res.length && participant === res[idx].label) {
        end = res[idx].end;
        idx++;
      }
      participants.push({
        label: participant,
        cssLabel: cssParticipantLabel,
        start: start,
        end: end,
      });
    } else {
      participants.push(res[idx]);
      idx++;
    }
  }

  return participants;
};

export const extractExactMatchTopicsInsights = (
  exactMatchTopics: RecordingTopic[],
  totalDuration: number
): RecordingTrackSegment[] => {
  const res: RecordingTrackSegment[] = []
  exactMatchTopics.forEach((m) => {
    if (m.timeInSeconds < totalDuration) {
      res.push({
        label: m.topicName.name,
        cssLabel:
          momentsTrackCssClasses[
            m.topicName.id % momentsTrackCssClasses.length
          ],
        start: m.timeInSeconds,
        end:
          m.timeInSeconds + totalDuration * 0.01 > totalDuration
            ? m.timeInSeconds + 1
            : m.timeInSeconds + totalDuration * 0.01,
      });
    }
  });
  return res;
};

export const extractSegmentSentimentInsights = (
  segmentSentiments: RecordingSegmentSentiment[]
): RecordingTrackSegment[] => {
  const res: RecordingTrackSegment[] = [];

  const uniqueSentiments = new Set<string>();
  uniqueSentiments.add("Positive");
  uniqueSentiments.add("Negative");
  uniqueSentiments.add("Neutral");

  let positiveSentimentFound = false;
  let negativeSentimentFound = false;
  let neutralSentimentFound = false;

  segmentSentiments.forEach((s) => {
    let sentiment = "";
    if (
      s.positivePrediction > s.negativePrediction &&
      s.positivePrediction > s.neutralPrediction
    ) {
      sentiment = "Positive";
      positiveSentimentFound = true;
    } else if (
      s.negativePrediction > s.positivePrediction &&
      s.negativePrediction > s.neutralPrediction
    ) {
      sentiment = "Negative";
      negativeSentimentFound = true;
    } 
    else if (
      s.neutralPrediction > s.positivePrediction &&
      s.neutralPrediction > s.negativePrediction
    ) {
      sentiment = "Neutral";
      neutralSentimentFound = true;
    }
    else {
      // sentiment = "0";
    }

    if(sentiment !== ""){
      res.push({
        label: sentiment,
        cssLabel: sentimentsTrackCssClasses.get(sentiment) ?? "unhappy bg-black-5",
        start: s.startTime,
        end: s.endTime,
      });
    }
  });

  if (!positiveSentimentFound) {
    res.push({
      label: "Positive",
      cssLabel: sentimentsTrackCssClasses.get("Positive"),
      start: 0,
      end: 0,
    });
  }
  if (!negativeSentimentFound) {
    res.push({
      label: "Negative",
      cssLabel: sentimentsTrackCssClasses.get("Negative"),
      start: 0,
      end: 0,
    });
  }
  if (!neutralSentimentFound) {
    res.push({
      label: "Neutral",
      cssLabel: sentimentsTrackCssClasses.get("Neutral"),
      start: 0,
      end: 0,
    });
  }

  return res;
};
