import React, { useEffect, useState } from "react";
import FileDownload from "js-file-download";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Loader } from "@progress/kendo-react-indicators";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridNoRecords,
  GridPageChangeEvent,
  GridRowProps,
  GridSortChangeEvent,
  GridHeaderCellProps
} from "@progress/kendo-react-grid";
import CallPlayer from "./CallPlayer";
import ShareDialog from "../ShareDialog";
import CallListingForMobile from "./CallListingForMobile";
import useAuth from "../../hooks/useAuth";
import useLocale from "../../hooks/useLocale";
import { CallDetail } from "../../types/call";
import { Dictionary } from "../../types/Dictionary";
import { ColorCategory } from "../../types/master-data";
import callsService from "../../services/calls.service";
import { AccessPermissionEnum } from "../../enums";
import { CallDetailTabEnum } from "../../enums/callDetailTabEnum";
import CustomUserGridInfo from "./grid/CustomUserGridInfo";
import CustomGridCellForDateTime from "./grid/CustomGridCellForDateTime";
import CustomGridCellForCallTopic from "./grid/CustomGridCellForCallTopic";
import CustomGridCellForCallOutcome from "./grid/CustomGridCellForCallOutcome";
import CustomGridCellForCallComments from "./grid/CustomGridCellForCallComments";
import CustomGridCellForCallPlayIcon from "./grid/CustomGridCellForCallPlayIcon";
import CustomGridCellForCallDirection from "./grid/CustomGridCellForCallDirection";
import CustomGridCellForDurationSeconds from "./grid/CustomGridCellForDurationSeconds";
import CustomGridCellForCallGroupedIcons from "./grid/CustomGridCellForCallGroupedIcons";
import { axiosWithAuth } from "../../utils/customAxios";
import { searchCallData } from "../../utils/callDetailUtil";
import useSwal from "../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import { Page } from "../../types/Page";
import { CallTableSortColumnEnum } from "../../enums/CallTableSortColumnEnum";
import CustomGridCellForCallTag from "./grid/CustomGridCellForCallTag";
import ParticipantPopup from "./ParticipantPopup";
import {
  Input,
  Checkbox,
  CheckboxChangeEvent,
  InputChangeEvent,
} from "@progress/kendo-react-inputs";
import useTranslation from "../../hooks/useTranslation";

const initialDataState = {
  skip: 0,
  take: 10,
};
let initialSort: Array<SortDescriptor> = [{ field: "callTime", dir: "desc" }];

type CallListingProps = {
  isBasic?: boolean;
  loading?: boolean;
  error?: boolean;
  callData: CallDetail[] | undefined;
  handleCallFlagUpdate?: (
    selectedFlag: ColorCategory | null,
    callDetailData: CallDetail
  ) => void;
  enableServerSidePagination?: boolean;
  searchTerm: string;
  pageDetail?: Page;
  handlePaginationChange?: (pageDetail: Page) => void;
  handleSortingChange?: (sortField: string, sortDirection: string) => void;
};

const CallPlayerComponent = (props: any) => {
  localStorage.setItem("callPlayerState", "participantHistoryTab");
  return (
    <div className="ListPlayer p-b-0">
      <CallPlayer
        enableRegions={false}
        recordingId={props?.dataItem?.recordings[0]?.id}
        showSkipSilenceBtn={false}
        durationInSeconds={
          props?.dataItem?.recordings[0]?.recordingDurationInSeconds
        }
        wavformContainerId="participantHistoryTab"
      />
    </div>
  );
};

const CallListing: React.FC<CallListingProps> = ({
  isBasic = false,
  loading,
  error,
  callData,
  handleCallFlagUpdate,
  enableServerSidePagination = false,
  searchTerm,
  pageDetail,
  handlePaginationChange,
  handleSortingChange,
}) => {
  const trans=useTranslation("calls");
  const auth = useAuth();
  const swal = useSwal();
  const localeCtx = useLocale();
  const [page, setPage] = useState(initialDataState);
  const [sort, setSort] = React.useState<SortDescriptor[]>([]);
  const [calls, setCalls] = useState<CallDetail[] | undefined>();

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["calls"]) {
      trans.fetchTranslations("calls");
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    setCalls(callData);
    setPage(initialDataState);
    if (enableServerSidePagination && pageDetail && pageDetail.sort) {
      let _sortField: string = "";
      switch (pageDetail.sort.sortField) {
        case CallTableSortColumnEnum.DateTime:
          _sortField = "callTime";
          break;
        case CallTableSortColumnEnum.FirstParty:
          _sortField = "firstParty.email";
          break;
        case CallTableSortColumnEnum.SecondParty:
          _sortField = "secondParty.email";
          break;
        case CallTableSortColumnEnum.Duration:
          _sortField = "callDuration";
          break;
        case CallTableSortColumnEnum.Comment:
          _sortField = "recordings[0].totalComments";
          break;
        case CallTableSortColumnEnum.Outcome:
          _sortField = "callResult.name";
          break;
        default:
          _sortField = "callTime";
          break;
      }
      setSort([
        {
          field: _sortField,
          dir: pageDetail.sort.sortDirection === "asc" ? "asc" : "desc",
        },
      ]);
    }
  }, [callData]);

  useEffect(() => {
    setPage(initialDataState);
  }, [searchTerm]);


  const handlePageChange = (event: GridPageChangeEvent) => {
    if (enableServerSidePagination == true && handlePaginationChange) {
      handlePaginationChange({
        ...pageDetail,
        skip: event.page.skip,
        take: event.page.take,
      });
    } else {
      setPage(event.page);
    }
  };

  const handleSortChange = (event: GridSortChangeEvent) => {
    let tempSort = event.sort;
    if (event.sort.length === 0 && sort.length !== 0) {
      tempSort = sort.map((s) => {
        return { field: s.field, dir: "asc" };
      });
    }
    setSort(tempSort);
    if (enableServerSidePagination === true && handleSortingChange) {
      let _sort = tempSort[0];
      let _sortField: string = "";
      let _sortDir: string | undefined = _sort.dir;
      switch (_sort.field) {
        case "callTime":
          _sortField = CallTableSortColumnEnum.DateTime;
          break;
        case "firstParty.email":
          _sortField = CallTableSortColumnEnum.FirstParty;
          break;
        case "secondParty.email":
          _sortField = CallTableSortColumnEnum.SecondParty;
          break;
        case "callDuration":
          _sortField = CallTableSortColumnEnum.Duration;
          break;
        case "recordings[0].totalComments":
          _sortField = CallTableSortColumnEnum.Comment;
          break;
        case "callResult.name":
          _sortField = CallTableSortColumnEnum.Outcome;
          break;
        default:
          _sortField = CallTableSortColumnEnum.DateTime;
          _sortDir = "desc";
          break;
      }
      handleSortingChange(_sortField, _sortDir ?? "asc");
    }
  };

  const handleFlagSelection = async (
    selectedFlag: ColorCategory,
    callDetailData: CallDetail
  ) => {
    await callsService
      .createCallDetailCategory(
        callDetailData.platformCallDetailId,
        selectedFlag
      )
      .then((response) => {
        if (handleCallFlagUpdate)
          handleCallFlagUpdate(selectedFlag, callDetailData);
      })
      .catch(async (err) => {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Failed to update call flag. Please try again.",
        };
        await swal.fire(swalOptions);
      });
  };

  const handlePlayerExpand = (callDetail: CallDetail) => {
    let newCallData = calls?.map((item: CallDetail) => {
      if (item.platformCallDetailId === callDetail.platformCallDetailId) {
        item.expanded = !callDetail.expanded;
      } else {
        item.expanded = false;
      }
      return item;
    });
    setCalls(newCallData as CallDetail[]);
  };

  const navigateToCallDetailPage = (
    callDetail: CallDetail,
    tab: CallDetailTabEnum
  ) => {
    const recordingId = callDetail.recordings[0].id;
    const routeUrl = `/calldetail/${callDetail.platformCallDetailId}?recordingId=${recordingId}&selectedtab=${tab}`;
    window.open(`${window.location.origin}${routeUrl}`);
  };

  const CustomCellForMobileView = (mobileViewProps: GridCellProps) => {
    return (
      <CallListingForMobile
        {...mobileViewProps}
        handlePlayerExpand={handlePlayerExpand}
      />
    );
  };

  const CustomCellWithPlayIcon = (playIconProps: GridCellProps) => {
    return (
      <CustomGridCellForCallPlayIcon
        {...playIconProps}
        handlePlayerExpand={handlePlayerExpand}
      />
    );
  };

  const CustomGCellWithCallParty = (callPartyprops: GridCellProps) => {
    // console.log("callPartyprops", callPartyprops.dataItem.field);

    const [visible, setVisible] = React.useState<boolean>(false);
    const toggleDialog = () => {
      setVisible(!visible);
    };
    const user =
      callPartyprops.field === "firstParty.email"
        ? callPartyprops.dataItem.firstParty
        : callPartyprops.dataItem.secondParties.find((sp: any) => sp.id === 0) ?? callPartyprops.dataItem.secondParties[0];
    const callDetailEnum =
      callPartyprops.field === "firstParty.email"
        ? CallDetailTabEnum.Transcript
        : CallDetailTabEnum.ParticipantHistory;

    const uniqueParticipants = callPartyprops.dataItem.secondParties.filter((value:any, index:any, self:any) =>
      index == self.findIndex((obj:any) => 
      obj.email === value.email)); 

    return (
      <td className="uCardCol">
        <div className="moreData">
          <div
            onClick={() =>
              navigateToCallDetailPage(callPartyprops.dataItem, callDetailEnum)
            }
          >
            {callPartyprops.field === "secondParty.email" ? (
              <>
                <CustomUserGridInfo userInfo={user} />
              </>
            ) : (
              <CustomUserGridInfo userInfo={user} />
            )}
          </div>
          {(callPartyprops.field === "secondParty.email" 
          && callPartyprops.dataItem.secondParties.length > 1)&& (
            <div className="moreDataInr">
              <Tooltip
                anchorElement={"target"}
                position={"top"}
                parentTitle={true}
              >
                <div className="moreDataList">
                  <div
                    className="moreDataListBtn"
                    style={{ cursor: "pointer" }}
                    title={`${
                      trans.translationsLoading
                        ? "More Participants"
                        : trans.fetchLabelKeyTranslation(
                            "lblMoreParticipants",
                            "More Participants"
                          )
                    }`}
                    onClick={toggleDialog}
                  >
                    <i className="bi bi-caret-down-fill text-black-9"></i>
                  </div>
                  {visible && (
                    <ParticipantPopup                    
                      toggleDialog={toggleDialog}
                      participants={uniqueParticipants}
                    ></ParticipantPopup>
                  )}
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </td>
    );
  };

  const CustomCellForIconGroup = (iconGroupProps: GridCellProps) => {
    return (
      <CustomGridCellForCallGroupedIcons
        {...iconGroupProps}
        handleFlagSelection={handleFlagSelection}
        handleCallDetailNavigation={navigateToCallDetailPage}
      />
    );
  };

  const CustomCellForComments = (commentsProps: GridCellProps) => {
    return (
      <CustomGridCellForCallComments
        {...commentsProps}
        handleCallDetailNavigation={navigateToCallDetailPage}
      />
    );
  };

  const CustomCellForOutcome = (outcomeProps: GridCellProps) => {
    return (
      <CustomGridCellForCallOutcome
        {...outcomeProps}
        handleCallDetailNavigation={navigateToCallDetailPage}
      />
    );
  };

  const CustomCellForCallTag = (callTagProps: GridCellProps) => {
    return <CustomGridCellForCallTag {...callTagProps} />;
  };

  const CustomCellForReasonCode = (reasonCodeProps: GridCellProps) => {
    return (
      <td className="mx-th-tag">
        <div
          className="mx-td-spn line-height-2"
          style={{ wordBreak: "break-word" }}
        >
          {reasonCodeProps.dataItem.reasonCode?.name}
        </div>
      </td>
    );
  };

  const CustomCellWithActions = (actionProps: GridCellProps) => {
    const recordingId = actionProps.dataItem.recordings[0].id;
    const routeUrl = `/calldetail/${actionProps.dataItem.platformCallDetailId}?recordingId=${recordingId}`;
    const [downloadingRecording, setDownloadingRecording] =
      useState<Boolean>(false);
    const [shareDialogVisible, setShareDialogVisible] =
      useState<boolean>(false);

    const toggleShareDialogHandler = () => {
      setShareDialogVisible((prev) => !prev);
    };

    const onCloseShareDialog = () => {
      setShareDialogVisible(false);
    };

    const downloadRecording = async () => {
      if (downloadingRecording) {
        return;
      }
      setDownloadingRecording(true);
      var response = await axiosWithAuth.get(
        `recording/${recordingId}/download`,
        { responseType: "arraybuffer" }
      );
      if (response.data) {
        FileDownload(response.data, `${recordingId}_callRecording.wav`);
      } else {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Recording download failed. Please try again.",
        };
        await swal.fire(swalOptions);
      }
      setDownloadingRecording(false);
    };

    return (
      <td className="text-right">
        {shareDialogVisible && (
          <ShareDialog
            onClose={onCloseShareDialog}
            resourceObjId={actionProps.dataItem.id}
            recordingPath={routeUrl}
          />
        )}
        <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
          <span className="iBtn iBtnDisable d-flex justify-content-end">
            <Button
              className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3`}
              onClick={() =>
                navigateToCallDetailPage(
                  actionProps.dataItem,
                  CallDetailTabEnum.Transcript
                )
              }
              disabled={(actionProps.dataItem?.recordings[0]?.isAIAnalysed == false) ? true : false}
            >
              <span
                className={`fs-17 line-height-1 ${(actionProps.dataItem?.recordings[0]?.isAIAnalysed) ? 'text-primary' : 'text-black-5'}`}
                title="Transcript"
              >
                <i className="bi bi-file-text"></i>
              </span>
            </Button>
            <Button
              className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 ${
                auth?.checkUserAccess(AccessPermissionEnum.ShareCalls)
                  ? "iconBtn"
                  : "disabledIconBtn"
              }
                  `}
              onClick={() => downloadRecording()}
              disabled={!auth?.checkUserAccess(AccessPermissionEnum.ShareCalls)}
            >
              <span
                className={`fs-17 line-height-1 text-primary ${
                  downloadingRecording && "blinkDownloadIcon"
                }`}
                title={downloadingRecording ? "Downloading..." : "Download"}
              >
                <i className="bi bi-download"></i>
              </span>
            </Button>
            <Button
              className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 ${
                auth?.checkUserAccess(AccessPermissionEnum.ShareCalls)
                  ? "iconBtn"
                  : "disabledIconBtn"
              }
                  `}
              onClick={toggleShareDialogHandler}
              disabled={!auth?.checkUserAccess(AccessPermissionEnum.ShareCalls)}
            >
              <span className="fs-15 line-height-1 text-primary" title="Share">
                <i className="bi bi-share"></i>
              </span>
            </Button>
          </span>
        </Tooltip>
      </td>
    );
  };

  const rowRender = (
    row: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) => {
    const expanded = props.dataItem.expanded;
    const trProps: any = {
      className: expanded ? "highlightRow" : "",
    };
    return React.cloneElement(
      row,
      { ...trProps },
      row.props.children as React.ReactNode
    );
  };

  
  const NotClickableHeader = (props: GridHeaderCellProps) => {
    return (
      <div>
        <span style={{ cursor: "default" }}>{props.title}</span>
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="tableList rowSpace float-left w-100 listCalls">
          <div className="tableMain gridContentOverflowY-hidden table-mobile">
            <Grid
              detail={CallPlayerComponent}
              data={
                enableServerSidePagination
                  ? calls
                  : orderBy(
                      calls
                        ?.filter((el) => searchCallData(el, searchTerm))
                        .slice(page.skip, page.take + page.skip) ?? [],
                      sort
                    )
              }
              skip={enableServerSidePagination ? pageDetail?.skip : page.skip}
              take={enableServerSidePagination ? pageDetail?.take : page.take}
              total={
                enableServerSidePagination
                  ? pageDetail?.totalRecords
                  : callData?.filter((el) => searchCallData(el, searchTerm))
                      .length
              }
              pageable={{ buttonCount: 4, pageSizes: true }}
              onPageChange={handlePageChange}
              expandField="expanded"
              sortable={true}
              sort={sort}
              onSortChange={handleSortChange}
              rowRender={rowRender}
            >
              <GridNoRecords>
                {loading && <Loader type={"infinite-spinner"} />}
                {!loading &&
                  !error &&
                  calls?.filter((el) => searchCallData(el, searchTerm))
                    ?.length === 0 && (
                    <span>{`${
                      trans.translationsLoading
                        ? "No Records Available"
                        : trans.fetchLabelKeyTranslation(
                            "MsgRecordsnotAvail",
                            "No Records Available"
                          )
                    }`}</span>
                  )}
                {error && (
                  <span className="fs-15">
                    <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                    {`${
                      trans.translationsLoading
                        ? "Uh Oh! Something Went Wrong. Please Try Again!"
                        : trans.fetchLabelKeyTranslation(
                            "ErrMsg",
                            "Uh Oh! Something Went Wrong. Please Try Again!"
                          )
                    }`}
                  </span>
                )}
              </GridNoRecords>
              <GridColumn
                width={0}
                field="cellMobile"
                cell={CustomCellForMobileView}
              />
              <GridColumn title="" cell={CustomCellWithPlayIcon} />
              <GridColumn
                field="callTime"
                title={`${
                  trans.translationsLoading
                    ? "Date/Time"
                    : trans.fetchLabelKeyTranslation(
                        "CallsGridDateTimeColLabel",
                        "Date/Time"
                      )
                }`}
                cell={CustomGridCellForDateTime}
                className="line-height-2"
                // width={115}
              />
              <GridColumn
                field="firstParty.email"
                title={`${
                  trans.translationsLoading
                    ? "Recorded User"
                    : trans.fetchLabelKeyTranslation(
                        "CallsGridFirstPartyColLabel",
                        "Recorded User"
                      )
                }`}
                cell={CustomGCellWithCallParty}
                minResizableWidth={220}
                // width={220}
              />
              <GridColumn
                title=""
                cell={CustomGridCellForCallDirection}
                // width={60}
              />
              <GridColumn
                field="secondParty.email"
                title={`${
                  trans.translationsLoading
                    ? "Participant"
                    : trans.fetchLabelKeyTranslation(
                        "CallsGridRemotePartyColLabel",
                        "Participant"
                      )
                }`}
                cell={CustomGCellWithCallParty}
                // width={220}
              />
              <GridColumn
                field="callDuration"
                title={`${
                  trans.translationsLoading
                    ? "Duration"
                    : trans.fetchLabelKeyTranslation(
                        "CallsGridDurationColLabel",
                        "Duration"
                      )
                }`}
                cell={CustomGridCellForDurationSeconds}
                // width={90}
              />
              <GridColumn title="" cell={CustomCellForIconGroup} />
              {auth?.checkTopicAvailibility() && !isBasic && (
                <GridColumn
                  field="callTopics"
                  title={`${
                    trans.translationsLoading
                      ? "Topics"
                      : trans.fetchLabelKeyTranslation(
                          "CallsGridTopicsColLabel",
                          "Topics"
                        )
                  }`}
                  cell={CustomGridCellForCallTopic}
                  sortable={false}
                  // width={180}
                />
              )}
              {!isBasic && (
                <GridColumn
                  title=""
                  cell={CustomCellForComments}
                  // width={110}
                />
              )}
              {!isBasic && (
                <GridColumn
                  field="callResult.name"
                  title={`${
                    trans.translationsLoading
                      ? "Call Outcomes"
                      : trans.fetchLabelKeyTranslation(
                          "CallsGridCallOutcomeColLabel",
                          "Call Outcomes"
                        )
                  }`}
                  cell={CustomCellForOutcome}                  
                  // width={160}
                />
              )}
              {!isBasic && (
                <GridColumn
                  field="reasonCode.name"
                  title={`${
                    trans.translationsLoading
                      ? "Reason Code"
                      : trans.fetchLabelKeyTranslation(
                          "CallsGridRCColLabel",
                          "Reason Code"
                        )
                  }`}
                  cell={CustomCellForReasonCode}
                  sortable={false}
                  // width={160}
                />
              )}
              {!isBasic && (
                <GridColumn
                  field="callTag"
                  title={`${
                    trans.translationsLoading
                      ? "Notes"
                      : trans.fetchLabelKeyTranslation(
                          "CallsGridNotesColLabel",
                          "Notes"
                        )
                  }`}
                  cell={CustomCellForCallTag}
                  sortable={false}
                  // width={160}
                />
              )}
              <GridColumn
                field=""
                title={`${
                  trans.translationsLoading
                    ? "Actions"
                    : trans.fetchLabelKeyTranslation(
                        "CallsGridActionsColLabel",
                        "Actions"
                      )
                }`}
                sortable={false}
                cell={CustomCellWithActions}
                headerCell={NotClickableHeader}
                // width={130}
              />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallListing;
