import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AuthorizationProfile } from "../../../../types/authorization-profile/AuthorizationProfile";
import { TinyUser, User } from "../../../../types/user";
import CustomUserGridInfo from "../../../../components/custom/grid/CustomUserGridInfo";
import { UserAuthProfile } from "../../../../types/user/UserAuthProfile";
import { Button } from "@progress/kendo-react-buttons";
import usersService from "../../../../services/users.service";
import { Dictionary } from "../../../../types/Dictionary";
import useLocale from "../../../../hooks/useLocale";
import useSwal from "../../../../hooks/useSwal";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import authorizationProfileService from "../../../../services/authorizationprofile.service";
import { sortUsersData } from "../../../../utils/userUtil";
import useTranslation from "../../../../hooks/useTranslation";

interface UpsertProfileUsersDialogProps {
  profile: AuthorizationProfile;
  users: User[];
  freezeProfile : string;
  onCloseDialog: () => void;
  onProfileUserUpsert: (
    upsertedProfileUsers: User[],
    profile: AuthorizationProfile
  ) => void;
}

interface ProfileUser extends User {
  removeable?: boolean;
}

interface GroupedProfileUser {
  categoryName: string;
  users: ProfileUser[];
}

const UpsertProfileUsersDialog: React.FC<UpsertProfileUsersDialogProps> = ({
  profile,
  users,
  freezeProfile,
  onCloseDialog,
  onProfileUserUpsert,
}) => {
  const trans=useTranslation("UpsertProfileUsersDialog");
  const Swal = useSwal()
  const localeCtx = useLocale();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [assignedUsers, setAssignedUsers] = useState<ProfileUser[]>([]);
  const [constAssignedUsers, setConstAssignedUsers] = useState<ProfileUser[]>(
    []
  );
  const [updateButton, setUpdateButton] = useState<boolean>(true);
  const [unassignedUsers, setUnassignedUsers] = useState<ProfileUser[]>([]);
  const [groupedUnassignedUsers, setGroupedUnassignedUsers] = useState<
    GroupedProfileUser[]
  >([]);
  const [assignedUserSearch, setAssignedUserSearch] = useState<string>("");
  const [unassignedUserSearch, setUnassignedUserSearch] = useState<string>("");
  const [unassignedUsersListManagement, setUnassignedUsersListManagement] =
    useState<GroupedProfileUser[]>([]);

  useEffect(() => {
    trans.fetchTranslations("UpsertProfileUsersDialog");
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    setAssignedUsers(
      users.filter((u) => u.authorizationProfile?.id === profile?.id)
    );
    setConstAssignedUsers(
      users.filter((u) => u.authorizationProfile?.id === profile?.id)
    );
    const tempUnassignedUsers = freezeProfile == "true" ? 
    users.filter((u) =>  u.authorizationProfile?.id !== profile?.id 
    && profile?.id != 1 && u.authorizationProfile?.id != 1).map((el) => {return { ...el, removeable: true };}) :
    users
      .filter((u) => u.authorizationProfile?.id !== profile?.id)
      .map((el) => {return { ...el, removeable: true }})

    setUnassignedUsers(tempUnassignedUsers);
  }, [profile, users]);

  useEffect(() => {
    var groupedpUsers: GroupedProfileUser[] = [];
    unassignedUsers
      .filter((el) => searchUser(el, unassignedUserSearch))
      .map((user) => {
        var authProfile =
          user.authorizationProfile !== undefined
            ? user.authorizationProfile.name
            : "No Permission Group";
        var index = groupedpUsers.findIndex(
          (gu) => gu?.categoryName === authProfile
        );
        if (index === -1) {
          var newGroup: GroupedProfileUser = {
            categoryName: authProfile,
            users: [user],
          };
          if (
            unassignedUsersListManagement &&
            unassignedUsersListManagement.length !== 0
          ) {
            const getIndex = unassignedUsersListManagement?.findIndex(
              (user) => user?.categoryName === authProfile
            );
            groupedpUsers[getIndex] = newGroup;
          } else {
            groupedpUsers.push(newGroup);
          }
        } else {
          groupedpUsers[index].users.push(user);
        }
      });

    if (groupedUnassignedUsers && groupedUnassignedUsers.length === 0) {
      setUnassignedUsersListManagement(groupedpUsers);
    }
    setGroupedUnassignedUsers(groupedpUsers);
    if (JSON.stringify(assignedUsers) !== JSON.stringify(constAssignedUsers)) {
      setUpdateButton(false);
    } else {
      setUpdateButton(true);
    }
  }, [unassignedUsers, unassignedUserSearch]);

  const handleAssignedUserSearchChange = (event: any) => {
    setAssignedUserSearch(event.value);
  };

  const handleUnassignedUserSearchChange = (event: any) => {
    setUnassignedUsersListManagement([]);
    setUnassignedUserSearch(event.value);
  };

  const searchUser = (userDetails: ProfileUser, searchTerm: string) => {
    var searchText = searchTerm.trim().toLocaleLowerCase();
    var firstName = userDetails.firstName ? userDetails?.firstName : "";
    var lastName = userDetails.lastName ? userDetails.lastName : "";
    var userName = `${firstName} ${lastName}`.trim();
    if (
      userDetails?.email?.toLocaleLowerCase().includes(searchText) ||
      userName?.toLocaleLowerCase().includes(searchText)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const submitHandler = async () => {
    setLoading(true);
    var userAuthProfiles: UserAuthProfile[] = [];
    assignedUsers.forEach((el) => {
      if (el.removeable !== undefined && el.removeable) {
        var _userAuthProfile: UserAuthProfile = {
          id: el.id,
          loginUserId: el.loginUserId,
          firstName: el.firstName ?? "",
          lastName: el.lastName ?? "",
          email: el.email ?? "",
          phoneNo: "",
          image: el.image ?? "",
          isArchived: el.isArchived,
          authorizationProfileId: profile.id,
        };
        userAuthProfiles.push(_userAuthProfile);
      }
    });
    usersService
      .updateUserAuthProfile(userAuthProfiles)
      .then((updatedUsers) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: trans.fetchLabelKeyTranslation(
            "UPGupdatedTitle",
            "Users Permission Group updated."
          ),
        });
        onProfileUserUpsert(assignedUsers, profile);
        onCloseDialog();
      })
      .catch((err) => {
        setLoading(false);
        if (err instanceof Error) {
          setError(
            trans.fetchLabelKeyTranslation(
              "SomethingError",
              "Something went wrong! Please try again"
            )
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddUser = (userDetails: ProfileUser) => {
    setAssignedUsers([userDetails, ...assignedUsers]);
    setUnassignedUsers(
      unassignedUsers.filter((ele) => ele.id !== userDetails.id)
    );
    if (groupedUnassignedUsers.length !== 0)
      setUnassignedUsersListManagement(groupedUnassignedUsers);
  };

  const handleDeleteUser = (userDetails: ProfileUser) => {
    const tempUnassignedUsers = [...unassignedUsers, userDetails];
    const sortedUsers = sortUsersData([...tempUnassignedUsers])
    setUnassignedUsers(sortedUsers);
    setAssignedUsers(assignedUsers.filter((ele) => ele.id !== userDetails.id));
  };

  return (
    <Dialog
      title={trans.fetchLabelKeyTranslation("AddUsersTitle", "Add Users")}
      onClose={onCloseDialog}
      className="dialogAddUserAuth"
    >
      {loading && (
        <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px"}}
          themeColor={"light"}
          size={"medium"}
        />
      )}
      <Tooltip anchorElement={"target"} parentTitle={true} position={"top"}>
        <div className="userListingData" style={{ maxWidth: "720px" }}>
          <div className="row">
            <div className="col-md-6">
              <div className="userListingDataCol">
                <div className="userListingTitle fs-14 text-black-10 font-weight-bold p-b-4">
                  {trans.fetchLabelKeyTranslation(
                          "UnassignedUsersTitle",
                          "Unassigned Users"
                        )}
                </div>
                <div className="UserLiSearch p-b-5">
                  <div className="formInput">
                    <Input
                      className="form-control"
                      style={{
                        height: "32px",
                        background: "rgba(255,255,255,.6)",
                      }}
                      placeholder={trans.fetchLabelKeyTranslation(
                        "SearchUsersText",
                        "Search Users…"
                      )}
                      value={unassignedUserSearch}
                      onChange={handleUnassignedUserSearchChange}
                    />
                  </div>
                </div>
                <div
                  className="detailColBox scroller userListGroup"
                  style={{ height: "325px", overflow: "hidden auto" }}
                >
                  <PanelBar>
                    {groupedUnassignedUsers.map((group, index) => {
                      return (
                        <PanelBarItem
                          expanded={true}
                          title={group.categoryName}
                        >
                          {group.users.map((user) => {
                            var _user: TinyUser = {
                              id: user.id,
                              loginUserId: user.loginUserId,
                              firstName: user.firstName ?? "",
                              lastName: user.lastName ?? "",
                              email: user.email ?? "",
                              phoneNo: "",
                              image: user.image ?? "",
                              isArchived: user.isArchived
                            };
                            return (
                              <div className="UserRow w-100 float-left p-t-10 p-b-10 border-bottom-solid border-w-1 border-black-1">
                                <div className="tblUsr d-flex justify-content-between w-100">
                                  <CustomUserGridInfo userInfo={_user} />
                                  <div className="userActionBtn p-r-10">
                                    <a
                                      className="cursor-pointer"
                                      onClick={() => handleAddUser(user)}
                                    >
                                      <span
                                        className="fs-25 bg-black-4 text-primary radius-50 d-flex align-items-center justify-content-center"
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                        }}
                                        title={trans.fetchLabelKeyTranslation(
                                          "AddButton",
                                          "Add"
                                        )}
                                      >
                                        <i className="bi bi-arrow-right"></i>
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </PanelBarItem>
                      );
                    })}
                  </PanelBar>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="userListingDataCol">
                <div className="userListingTitle fs-14 text-black-10 font-weight-bold p-b-4">
                  {trans.fetchLabelKeyTranslation(
                          "AssignedUsersTitle",
                          "Group Members"
                        )}
                </div>
                <div className="UserLiSearch p-b-5">
                  <div className="formInput">
                    <Input
                      className="form-control"
                      style={{
                        height: "32px",
                        background: "rgba(255,255,255,.6)",
                      }}
                      placeholder={trans.fetchLabelKeyTranslation(
                        "SearchUsersText",
                        "Search"
                      )}
                      value={assignedUserSearch}
                      onChange={handleAssignedUserSearchChange}
                    />
                  </div>
                </div>
                <div
                  className="detailColBox scroller"
                  style={{ height: "290px", overflow: "hidden auto" }}
                >
                  {assignedUsers
                    .filter((el) => searchUser(el, assignedUserSearch))
                    .map((user) => {
                      var _user: TinyUser = {
                        id: user.id,
                        loginUserId: user.loginUserId,
                        firstName: user.firstName ?? "",
                        lastName: user.lastName ?? "",
                        email: user.email ?? "",
                        phoneNo: "",
                        image: user.image ?? "",
                        isArchived: user.isArchived
                      };
                      return (
                        <div className="UserRow w-100 float-left p-t-10 p-b-10 border-bottom-solid border-w-1 border-black-1">
                          <div className="tblUsr d-flex justify-content-between w-100">
                            <CustomUserGridInfo userInfo={_user} />
                            {user.removeable !== undefined &&
                              user.removeable && (
                                <div className="userActionBtn p-r-10">
                                  <a
                                    className="cursor-pointer"
                                    onClick={() => handleDeleteUser(user)}
                                  >
                                    <span
                                      className="fs-16 bg-black-4 text-primary radius-50 d-flex align-items-center justify-content-center"
                                      style={{ height: "30px", width: "30px" }}
                                      title={trans.fetchLabelKeyTranslation(
                                        "DeleteButton",
                                        "Delete"
                                      )}
                                    >
                                      <i className="bi bi-trash3"></i>
                                    </span>
                                  </a>
                                </div>
                              )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
      <DialogActionsBar>
        {error && (
          <span className="fs-15">
            <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
            {trans.fetchLabelKeyTranslation(
                    "WrongError",
                    "Uh Oh! Something Went Wrong. Please Try Again!"
                  )}
          </span>
        )}
        <Button onClick={onCloseDialog}>{trans.fetchLabelKeyTranslation("CancelButton", "Cancel")}</Button>
        <Button
          disabled={updateButton}
          className={`btn ${!updateButton && `bg-primary text-white`}`}
          onClick={submitHandler}
        >
          {loading ? (
            <Loader
              themeColor={"primary"}
              size={"small"}
              type={"infinite-spinner"}
            />
          ) : (
            trans.fetchLabelKeyTranslation("SaveButtonText", "Save")
          )}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default UpsertProfileUsersDialog;
